<script setup lang="ts">
import { ref } from 'vue';

    defineProps<{
        processing?: boolean 
        message?: string
        button_classes?: string
        hide_header?: boolean
        hide_footer?: boolean
    }>()

    const emits = defineEmits<{'opened'}>()

const modal = ref()

const toggleModal = () => {
    if(modal?.value?.open) {
        modal?.value?.classList.add("hide")
        setTimeout(() => modal?.value?.close(), 200)
        
    } else {
        emits('opened')
        modal?.value?.classList.remove("hide")
        modal?.value?.showModal()
    }
}

defineExpose({
  toggleModal
})


</script>

<template>
  <button
        type="button"
        :class="button_classes"
        @click.prevent="toggleModal()"
    >
        <slot name="button"></slot>
    </button>
    <dialog ref="modal" class="p-0 rounded-lg shadow-xl overflow-hidden" >
        <div :class="!hide_header && !hide_footer ? 'form_modal' : ''">
            <header class="flex gap-4 items-center bg-gray-900" v-if="!hide_header">
                <div class="flex-1 text-white px-8 py-4 uppercase tracking-widest font-bold text-lg">
                    <slot name="header"></slot>
                </div>
                <div class="h-12 flex items-center pr-8">
                    <button type="button" class="modal__close" @click.prevent="toggleModal()" :disabled="!!processing">
                        <svg
                            stroke="inherit"
                            fill="none"
                            width="1.2rem"
                            height="1.2rem"
                            viewBox="0 0 24 24"
                            
                            >
                            <path
                                class="inline-flex"
                                stroke-linecap="round"  
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                            </svg>
                    </button>
                </div>
            </header>
            <main class="bg-white grid h-full">
               <slot></slot>

            </main>
            <footer class="bg-gray-200 grid px-8 py-4" v-if="!hide_footer">
                <div class="bg-gray-200 flex justify-end gap-4">
                    <button type="button" @click.prevent="toggleModal()" class="inline-flex items-center px-4 py-2 bg-gray-600 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-500 active:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition ease-in-out duration-150"
                    :class="{ 'opacity-25': processing }" :disabled="!!processing"
                    >Close</button>
                    <slot name="submit_button"></slot>
                </div>
                <div v-if="message" class="text-sm text-gray-900 text-center mt-4">
                    {{ message }}
                </div>
            </footer>
        </div>
    </dialog>
  
</template>


<style>

dialog[open] {
    animation: show .3s ease normal;
}
dialog[open]::backdrop {
    animation: showBackdrop .3s ease normal;
}
dialog::backdrop {
    backdrop-filter: blur(2px);
}
dialog.hide {
    animation: hide .3s ease normal;
}
@keyframes show{
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes showBackdrop{
    from {
        backdrop-filter: blur(0px);
    }
    to {
        backdrop-filter: blur(2px);
    }
}
@keyframes hide{
    to {
        opacity: 0;
        transform: scale(0);
    }
}

.form_modal {
    display: grid;
    grid-template-rows: max-content auto max-content;
}
</style>